<template>
  <div class="col-md-5 order-md-2 order-1">
    <div class="order-review">
      <table>
        <tbody>
          <template v-for="(cart, index) in cartData.getCartItems">
            <tr>
              <td class="thumb">
                <div class="img-cont">
                  <img :src="cart.image" alt="" />
                  <!-- <span class="crl-count">1</span> -->
                </div>
              </td>
              <td>
                <h5 v-html="cart.name"></h5>
                <p v-html="cart.description"></p>
              </td>
              <td class="text-end">
                <span class="price"
                  >${{
                    ((cart.price * cart.quantity).toFixed(2)).toLocaleString("en-US")
                  }}</span
                >
                <a
                  href="javascript: ;"
                  @click="cartData.removeFromCart(cart)"
                  class="remove"
                  ><i class="ri-delete-bin-line"></i
                ></a>
              </td>
            </tr>
          </template>
          <template v-if="cartData.getCartCoupon == ''">
            <tr>
              <td colspan="3">
                <div class="coupon">
                  <input
                    type="text"
                    v-model="coupon_code"
                    name="coupon_code"
                    class="input-text"
                    id="coupon_code"
                    value=""
                    :placeholder="props.translationText.text.enterCode"
                  />
                  <button
                    type="button"
                    @click="applyCode"
                    class="btn btn-dark rc"
                    name=""
                  >
                    {{ props.translationText.text.apply }}
                  </button>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="3" style="text-align: left">
                <div class="default">
                  <span style="color: #41a823"
                    ><i class="ri-price-tag-3-fill"></i>
                    {{ cartData.getCartCouponCode }}
                    {{ props.translationText.text.codeApplied }}</span
                  >
                  <a href="javascript: ;" @click="cartData.resetApplyCoupon()"
                    ><i class="ri-close-line"></i
                  ></a>
                </div>
                <!-- <div class="msg">
                                        <h6> Using a discount coupon may void free shipping options. </h6>
                                        </div> -->
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <table>
        <tfoot>
          <tr>
            <td>{{ props.translationText.text.subtotal }}</td>
            <td>${{ cartData.getCartTotalPrice }}</td>
          </tr>
          <!-- <tr>
                                <td><div class="cde">Discount <i class="ri-price-tag-3-fill"></i> Prezerv Silver Retail Discount</div></td>
                                <td>- $24.87</td>
                            </tr> -->
          <tr>
            <td>{{ props.translationText.text.netShipping }}</td>
            <td>
              ${{ cartData.getShippingRate }}

              <template v-if="cartData.getShippingDiscount > 0"> - ({{
                cartData.getShippingDiscount
              }})</template>
            </td>
          </tr>

          <tr>
            <td>{{ props.translationText.text.shipping_tax }}</td>
            <td>
              ${{ cartData.getShippingTax }}

              <!-- <template v-if="cartData.getShippingDiscount > 0"> - ({{
                cartData.getShippingDiscount
              }})</template> -->
            </td>
          </tr>

          <tr>
            <td>{{ props.translationText.text.tax }}</td>
            <td>${{ cartData.getTaxTotal }}</td>
          </tr>
          <tr class="total">
            <td>
              <strong>{{ props.translationText.text.total }}:</strong>
            </td>
            <td>
              <strong>${{ cartData.getCartTotalNetPrice }}</strong>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="text-align: left">
              <div class="gurantee-text">
                <strong>{{ props.translationText.text.guranteeText }}</strong>
                <span class="gurantee-text-btn" style="width: 12%; float: right; display: block;">            
                  <img src="/assets/images/checkout/256_bit_secure.png" />
                </span>
              </div>
            </td>
          </tr>
          <!-- <tr>
            <td>
              <small>{{ props.translationText.text.monthlyPE }}</small>
            </td>
            <td>
              <small>${{ cartData.getCartTotalNetPrice }}</small>
            </td>
          </tr> -->
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onMounted } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import { useLoginStore } from "../../stores/login";
import { useCartStore } from "../../stores/cart";

const props = defineProps(["translationText", "baseURL"]);

const cartData = useCartStore();
//const cartItemData = ref([])
const route = useRoute();
const coupon_code = ref();
const quantity = ref(1);
const decreaseQty = () => {
  if (quantity.value > 1) {
    quantity.value--;
  }
};

const increaseQty = () => {
  quantity.value++;
};
const cartItem = {
  id: 1,
};
const openCartModal = () => {
  cartData.addToCart(cartItem);
  $("#cartModal").modal("show");
};
const applyCode = () => {

  if(cartData.getCartPurchaseType == true){
    swal({
        position: "top-end",
        icon: "error",
        title: "Autoship discount price is already applied.",
        button: false,
        timer: 2500,
      });
  }
  else{
    axios
    .post(
      props.baseURL + "discount",
      { name: coupon_code.value,qty:cartData.getCartCountQuantity, product:cartData.getCartItems },
      {
        validateStatus: function (status) {
          return status < 500; // Resolve only if the status code is less than 500
        },
      }
    )
    .then((response) => {
      //allStates.value = response.data.states;
      cartData.applyCoupon(response.data, coupon_code.value);
      console.log("getCartCoupon", cartData.getCartCoupon);
      swal({
        position: "top-end",
        icon: "success",
        title: "Coupon code applied",
        button: false,
        timer: 2500,
      });
    })
    .catch((error) => {
      swal({
        position: "top-end",
        icon: "error",
        title: "Coupon code not found",
        button: false,
        timer: 2500,
      });
    });

  }
  
};

const removeApplyCode = () => {
  cartData.resetApplyCoupon();
  coupon_code.value = "";
  isCoupnApply.value = false;
};
</script>
