<template>
  <div class="inner-height"></div>
  <section class="product-full-des">
    <div class="container-fluid">
      <div class="row">
        <ProductImage :product="productSingle"></ProductImage>
        <div class="col-md-7 summary entry-summary" data-aos="fade-up">
          <div class="pro-rating">
            <div class="left-rat">
              <div class="Stars">
                <i v-for="i in Math.floor(productSingle.product_rating)" :key="'full' + i" class="ri-star-fill"></i>
                <i v-if="productSingle.product_rating % 1 >= 0.5" class="ri-star-half-line"></i>
                <i v-for="i in 5 - Math.ceil(productSingle.product_rating)" :key="'empty' + i" class="ri-star-line"></i>
              </div>
              {{ productSingle.product_rating }}
            </div>
            <div class="Mid-text">
              <p>{{ productSingle.product_review }}</p>
            </div>
              <div class="btn login"><a :href="reviewLink">{{ translationText.text.reviewButtonText }}</a></div>
          </div>
          <h1 class="product_title" v-html="productSingle.name"></h1>
         
          <div class="product-details">
            <p v-html="productSingle.short_description"></p>
            <h5>{{ productSingle.description_tagline }}</h5>
          </div>

          <div class="price">
            <template v-if="userLogin.checkIsLoggedIn">
              <div v-if="userLogin.getUserUserData.user_type == 'ambassador'">
                <span v-if="showSalePrice == true">
                  <template
                    v-if="
                      productSingle.amb_sale_price !=
                      productSingle.amb_suggested_retail_price
                    "
                  >
                    <del>${{ productSingle.amb_sale_price }}</del>
                  </template>
                  ${{ productSingle.amb_suggested_retail_price }}
                </span>
                <span v-else>${{ productSingle.autoship_price_amb }}</span>
              </div>
              <div v-if="userLogin.getUserUserData.user_type == 'scholar'">
                <span v-if="showSalePrice == true">
                  <template
                    v-if="
                      productSingle.sc_sale_price !=
                      productSingle.sc_suggested_retail_price
                    "
                  >
                    <del>${{ productSingle.sc_sale_price }}</del>
                  </template>
                  ${{ productSingle.sc_suggested_retail_price }}</span
                >
                <span v-else>${{ productSingle.autoship_price_sc }}</span>
              </div>
              <div v-if="userLogin.getUserUserData.user_type == 'customer'">
                <span v-if="showSalePrice == true">
                  <template
                    v-if="
                      productSingle.sale_price !=
                      productSingle.suggested_retail_price
                    "
                  >
                    <del>${{ productSingle.sale_price }}</del>
                  </template>
                  ${{ productSingle.suggested_retail_price }}</span
                >
                <span v-else>${{ productSingle.autoship_price_customer }}</span>
              </div>
            </template>
            <template v-if="!userLogin.checkIsLoggedIn">
              <div v-if="!userLogin.checkIsLoggedIn">
                <span v-if="showSalePrice == true">
                  <template
                    v-if="
                      productSingle.sale_price !=
                      productSingle.suggested_retail_price
                    "
                  >
                    <del>${{ productSingle.sale_price }}</del>
                  </template>
                  ${{ productSingle.suggested_retail_price }}</span
                >
                <span v-else>${{ productSingle.autoship_price_customer }}</span>
              </div>
            </template>
          </div>
          <div class="product-details-price-tag">            
            <p>{{ translationText.text.priceTag }}: <strong>${{ productSingle.amb_suggested_retail_price }}</strong></p>
          </div>

          <div v-html="productSingle.description"></div>
          <Form novalidate @submit="openCartModal">
            <template v-if="productSingle.autoship == 'yes'">
              <div class="labels">
                <label class="form-radio">
                  <Field
                    rules="required"
                    type="radio"
                    name="purchase_type"
                    class="form-radio-value"
                    v-model="purchaseType"
                    value="1"
                  />
                  <span class="checkmark"></span>
                  {{ translationText.text.oneTime }}
                </label>
                <label class="form-radio">
                  <Field
                    rules="required"
                    type="radio"
                    name="purchase_type"
                    class="form-radio-value"
                    v-model="purchaseType"
                    value="2"
                  />
                  <span class="checkmark"></span>
                  {{ translationText.text.everyMonth }}
                </label>
                <ErrorMessage name="purchase_type" />
              </div>
            </template>

            <select v-model="quantity" class="form-control">
              <template v-for="quantityLoop in 60">
                <option :value="quantityLoop">{{ quantityLoop }}</option>
              </template>
            </select>
            <button type="submit" class="btn btn-dark buy-link">
              <i class="ri-shopping-cart-2-line"></i
              >{{ translationText.text.addToCart }}
            </button>
            <!-- <a
              href="javascript: ;"
              v-on:click="openCartModal"
              class="btn btn-dark buy-link"
              ><i class="ri-shopping-cart-2-line"></i
              >{{ translationText.text.addToCart }}</a
            > -->
          </Form>
        </div>
      </div>
    </div>
  </section>
  <ProductBeforeAfterImageDerma
    v-if="productSingle.id == 5"
    :translationText="translationText"
  ></ProductBeforeAfterImageDerma>
  <ProductBeforeAfterImage
    v-if="productSingle.id == 1"
    :translationText="translationText"
  ></ProductBeforeAfterImage>

  <ProductOverview
    :product="productSingle"
    :translationText="translationText"
  ></ProductOverview>

  <template v-if="productSingle.display_review == 'yes'">
    <section class="stories-sec">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-md-6" data-aos="fade-up">
          <div class="title">
            <h2>{{ translationText.text.realStoryText }}</h2>
          </div>

          <Carousel
            paginationColor="gray"
            paginationActiveColor="red"
            :itemsToShow="1"
            class="stories-slider"
            :wrapAround="false"
            :transition="500"
          >
            <Slide v-for="(slide, index) in reviews.user_reviewes" :key="index">
              <div class="carousel__item">
                <!-- <img :src="slide.image" alt="" class="quote" /> -->

                <p>
                  {{ slide.content }}
                </p>
                
                <h6>{{ slide.name }}</h6>
              </div>
            </Slide>

            <template #addons>
              <Pagination />
            </template>
          </Carousel>

          <div class="btn-left mt-md-5 mt-4">
            <router-link to="/testimonials" class="link line-animation">{{
              translationText.text.realStoryText
            }}</router-link>
          </div>
        </div>
        <div class="col-md-6 d-none d-md-block">
          <figure>
            <img
              src="/assets/images/Review-Section-Image.jpg"
              alt=""
              class="w-100 radius20"
            />
          </figure>
        </div>
      </div>
    </div>
  </section>
  </template>
  
  <!-- <ProductVideo></ProductVideo> -->
  <ProductFeature
    v-if="productSingle.id == 1"
    :translationText="translationText"
  ></ProductFeature>

  <ProductWhatAreYouWaitingFor
    v-if="productSingle.id == 5"
    :translationText="translationText"
  ></ProductWhatAreYouWaitingFor>
  <ProductTriozymeChewables
    v-if="productSingle.id == 6"
    :translationText="translationText"
  ></ProductTriozymeChewables>

  <ProductUneVie
    v-if="productSingle.id == 8"
    :translationText="translationText"
  ></ProductUneVie>

  <ProductRelated
    :baseURL="props.baseURL"
    :translationText="translationText"
  ></ProductRelated>

  <ProductListQuiz></ProductListQuiz>
</template>
<script setup>
import { ref, computed, defineProps, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import { useLoginStore } from "../../stores/login";
import { useCartStore } from "../../stores/cart";
import ProductBox from "../../components/Product/ProductBox";
import ProductListBanner from "../../components/Product/ProductListBanner";
import ProductListBox from "../../components/Product/ProductListBox";
import ProductListQuiz from "../../components/Product/ProductListQuiz";
import ProductListFilter from "../../components/Product/ProductListFilter";
import ProductRelated from "../../components/ProductDetails/ProductRelated";
import ProductImage from "../../components/ProductDetails/ProductImage";
import ProductBeforeAfterImage from "../../components/ProductDetails/ProductBeforeAfterImage";

import ProductBeforeAfterImageDerma from "../../components/ProductDetails/ProductBeforeAfterImageDerma";
import ProductWhatAreYouWaitingFor from "../../components/ProductDetails/ProductWhatAreYouWaitingFor";
import ProductTriozymeChewables from "../../components/ProductDetails/ProductTriozymeChewables";

import ProductUneVie from "../../components/ProductDetails/ProductUneVie";

import ProductSocial from "../../components/ProductDetails/ProductSocial";
import ProductAddToCartModal from "../../components/ProductDetails/ProductAddToCartModal";
import ProductFeature from "../../components/ProductDetails/ProductFeature";
import ProductVideo from "../../components/ProductDetails/ProductVideo";
import ProductOverview from "../../components/ProductDetails/ProductOverview";
import { userLanguage } from "../../stores/language";
import { Carousel, Pagination, Slide, Navigation } from "vue3-carousel";
// Validation
import { Form, Field, ErrorMessage, defineRule, configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import * as AllRules from "@vee-validate/rules";
Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});

const userLanguages = userLanguage();
const userLogin = useLoginStore();
const cartData = useCartStore();
const route = useRoute();
let props = defineProps(["baseURL"]);
//props: ["baseURL"],
console.log("id ", route.params.id);
const quantity = ref(1);
const purchaseType = ref(1);
const showSalePrice = ref(true);
const reviews = ref({
  user_reviewes:[]
});

const translationTextEn = ref({
  text: {
    realStoryText: "Success Stories From Real People",
    oneTime: "One-Time Purchase",
    everyMonth: "Every month/Smart buy",
    addToCart: "Add to Cart",
    healthyLiving: "A strong foundation is essential for healthy living",
    overView: "OVERVIEW",
    direction: "DIRECTIONS FOR USE",
    faq: "FAQ",
    documentation: "DOCUMENTATION",
    ecommerce:"ECOMMERCE MARKETING MATERIAL",
    gshComplex: "GSH COMPLEX",
    gshComplexSubtext:
      "An all-natural nutraceutical supplement for your body’s “master antioxidant\”",
    gshComplexDescription:
      "This is the engine that drives our health care therapy. Made from the highest quality and minimally processed fresh dairy whey, GSH Complex is formulated to stimulate the production of your body\’s glutathione. It is a key component to restoring, strengthening and rebalancing your immune system so that it can effectively protect your health and fend off illness — with no known side effects.",
    boost1: "Boosts, rebalances and strengthens your immune system",
    boost2: "Increases your body's ability to heal itself",
    boost3: "Detoxifies your whole body",
    boost4:
      "Ensures the proper functioning of, and prevents damage to, cells in your body",
    boost5: "Helps you to better absorb vital nutrients",
    gshComplexBehind: "The Science Behind GSH Complex",
    gshComplexGroundBreaking:
      "Our Groundbreaking Provida CVE Production Process",
    gshComplexGroundBreakingText1:
      "Backed by more than 30 years of scientific and medical knowledge,our team developed Provida CVE. This unique production process improves and maintains cellular vitality, making GSH Complex the only well-balanced protein peptide fuel of its kind for your cells.",
    gshComplexGroundBreakingText2:
      "GSH Complex® is backed by over 160,000 medical articles you can review on PubMed.",
    gshComplexGroundBreakingText3:
      "“In terms of daily diet supplementation, GSH Complex® has now taken on the status of a super food for promoting optimal health, for possible prevention of disease and for its potential as an anti-aging agent.”",
    gshComplexGroundBreakingText4:
      "INGREDIENTS: Whey Protein Isolate, Sunflower Lecithin (For solubility only <1%)",
    gshComplexGroundBreakingText5: "FORMAT: Family Pack (450 g/15.8 oz)",
    gshComplexGroundBreakingText6: "SUPPLY: 45 days",
    relatedProducts: "RELATED PRODUCTS",
    quickView: "QUICK VIEW",
    seeResults: "See Results in As Little as 30 Days!",
    whatAreYou: "What Are You Waiting For?",
    whatAreYouText:
      "You Too Can Have Smoother, Younger, and more Radiant Skin!",
    improveText:
      "Improves your skin's hydration, elasticity and overall health.",
    protectText:
      "Protects, soothes and repairs skin from harmful environmental effects, such as UV damage and sunburns.",
    reduceSign:
      "Reduces the signs of aging, giving you healthier, smoother and younger-looking skin.",
    containsnatural:
      "Contains all-natural, ethically-sourced and cruelty-free ingredients.",
    freeHarmful: "Free of harmful chemicals that can dry and damage your skin.",
    before: "Before",
    after: "After",

    triozymeText1:
      "Triozyme is a dietary enzymatic supplement that aids digestive function and fills nutritional gaps in your diet. Made from organic-certified whole grains (spelt, kamut and rye), Triozyme provides your body with essential nutrients in perfectly balanced proportions. It contains probiotics, prebiotics, dietary fibres, vitamins, minerals and more than 1,000 types of enzymes, making Triozyme a “super food” that has the ability to enhance your overall digestive health.",
    triozymeText2:
      "Balances your body's nutritional requirements and corrects deficiencies",
    triozymeText3: "Improves digestion and overall gut health",
    triozymeText4:
      "Maximizes your body's ability to absorb and distribute vital nutrients",
    triozymeText5: "Provides relief from acid reflux, indigestion and bloating",
    triozymeText6: "Combats Leaky Gut Syndrome and Irritable Bowel Syndrome",
    triozymeText7:
      "INGREDIENTS: Spelt, Rye, Kamut, acacia, granulated honey (organic), colloidal silicone dioxide, magnesium stearate, stearic acid, microcrystalline cellulose, magnesium trisilicate. (GMO-free & Artificial flavor-free).",
    triozymeText8: "FORMAT: 180 tablets",
    triozymeText9: "SUPPLY: 30 days",

    uneVieText1:
      "UNE VIE is structured water produced through a unique and extensive purification process. It acts as a highly efficient “delivery system” with powerful hydrating and restorative properties that transports vital nutrients to every cell in your body. Healthier than sugar-rich sport drinks and highly-caffeinated energy drinks, UNE VIE hydrates more effectively than drinking 10 glasses of water.",
    uneVieText2: "Hydrates your whole body at the cellular level",
    uneVieText3:
      "Stimulates and maximizes your cells' ability to absorb nutrients",
    uneVieText4: "Improves your metabolism",
    uneVieText5: "Promotes proper digestion and respiration",
    uneVieText6:
      "A 500ml bottle is equivalent to approximately 300 regular water bottles",
    uneVieText7: "INGREDIENTS: Water (Cellular Hydrating Solution)",
    uneVieText8: "FORMAT: 500 ml",
    uneVieText9: "SUPPLY: Solution; enough for about 300 regular water bottle",
    goodHealth: "Good For: Skin Health, Aging",
    priceTag:"For our valued scholar and ambassador",
    reviewButtonText: "SEE REVIEWS"
  },
  language: "",
});


const translationText = ref({
  text: {
    realStoryText: "Success Stories From Real People",
    oneTime: "One-Time Purchase",
    everyMonth: "Every month/Smart buy",
    addToCart: "Add to Cart",
    healthyLiving: "A strong foundation is essential for healthy living",
    overView: "OVERVIEW",
    direction: "DIRECTIONS FOR USE",
    faq: "FAQ",
    documentation: "DOCUMENTATION",
    ecommerce:"ECOMMERCE MARKETING MATERIAL",
    gshComplex: "GSH COMPLEX",
    gshComplexSubtext:
      "An all-natural nutraceutical supplement for your body’s “master antioxidant\”",
    gshComplexDescription:
      "This is the engine that drives our health care therapy. Made from the highest quality and minimally processed fresh dairy whey, GSH Complex is formulated to stimulate the production of your body\’s glutathione. It is a key component to restoring, strengthening and rebalancing your immune system so that it can effectively protect your health and fend off illness — with no known side effects.",
    boost1: "Boosts, rebalances and strengthens your immune system",
    boost2: "Increases your body's ability to heal itself",
    boost3: "Detoxifies your whole body",
    boost4:
      "Ensures the proper functioning of, and prevents damage to, cells in your body",
    boost5: "Helps you to better absorb vital nutrients",
    gshComplexBehind: "The Science Behind GSH Complex",
    gshComplexGroundBreaking:
      "Our Groundbreaking Provida CVE Production Process",
    gshComplexGroundBreakingText1:
      "Backed by more than 30 years of scientific and medical knowledge,our team developed Provida CVE. This unique production process improves and maintains cellular vitality, making GSH Complex the only well-balanced protein peptide fuel of its kind for your cells.",
    gshComplexGroundBreakingText2:
      "GSH Complex® is backed by over 160,000 medical articles you can review on PubMed.",
    gshComplexGroundBreakingText3:
      "“In terms of daily diet supplementation, GSH Complex® has now taken on the status of a super food for promoting optimal health, for possible prevention of disease and for its potential as an anti-aging agent.”",
    gshComplexGroundBreakingText4:
      "INGREDIENTS: Whey Protein Isolate, Sunflower Lecithin (For solubility only <1%)",
    gshComplexGroundBreakingText5: "FORMAT: Family Pack (450 g/15.8 oz)",
    gshComplexGroundBreakingText6: "SUPPLY: 45 days",
    relatedProducts: "RELATED PRODUCTS",
    quickView: "QUICK VIEW",
    seeResults: "See Results in As Little as 30 Days!",
    whatAreYou: "What Are You Waiting For?",
    whatAreYouText:
      "You Too Can Have Smoother, Younger, and more Radiant Skin!",
    improveText:
      "Improves your skin's hydration, elasticity and overall health.",
    protectText:
      "Protects, soothes and repairs skin from harmful environmental effects, such as UV damage and sunburns.",
    reduceSign:
      "Reduces the signs of aging, giving you healthier, smoother and younger-looking skin.",
    containsnatural:
      "Contains all-natural, ethically-sourced and cruelty-free ingredients.",
    freeHarmful: "Free of harmful chemicals that can dry and damage your skin.",
    before: "Before",
    after: "After",

    triozymeText1:
      "Triozyme is a dietary enzymatic supplement that aids digestive function and fills nutritional gaps in your diet. Made from organic-certified whole grains (spelt, kamut and rye), Triozyme provides your body with essential nutrients in perfectly balanced proportions. It contains probiotics, prebiotics, dietary fibres, vitamins, minerals and more than 1,000 types of enzymes, making Triozyme a “super food” that has the ability to enhance your overall digestive health.",
    triozymeText2:
      "Balances your body's nutritional requirements and corrects deficiencies",
    triozymeText3: "Improves digestion and overall gut health",
    triozymeText4:
      "Maximizes your body's ability to absorb and distribute vital nutrients",
    triozymeText5: "Provides relief from acid reflux, indigestion and bloating",
    triozymeText6: "Combats Leaky Gut Syndrome and Irritable Bowel Syndrome",
    triozymeText7:
      "INGREDIENTS: Spelt, Rye, Kamut, acacia, granulated honey (organic), colloidal silicone dioxide, magnesium stearate, stearic acid, microcrystalline cellulose, magnesium trisilicate. (GMO-free & Artificial flavor-free).",
    triozymeText8: "FORMAT: 180 tablets",
    triozymeText9: "SUPPLY: 30 days",

    uneVieText1:
      "UNE VIE is structured water produced through a unique and extensive purification process. It acts as a highly efficient “delivery system” with powerful hydrating and restorative properties that transports vital nutrients to every cell in your body. Healthier than sugar-rich sport drinks and highly-caffeinated energy drinks, UNE VIE hydrates more effectively than drinking 10 glasses of water.",
    uneVieText2: "Hydrates your whole body at the cellular level",
    uneVieText3:
      "Stimulates and maximizes your cells' ability to absorb nutrients",
    uneVieText4: "Improves your metabolism",
    uneVieText5: "Promotes proper digestion and respiration",
    uneVieText6:
      "A 500ml bottle is equivalent to approximately 300 regular water bottles",
    uneVieText7: "INGREDIENTS: Water (Cellular Hydrating Solution)",
    uneVieText8: "FORMAT: 500 ml",
    uneVieText9: "SUPPLY: Solution; enough for about 300 regular water bottle",
    goodHealth: "Good For: Skin Health, Aging",
    priceTag:"For our valued scholar and ambassador",
    reviewButtonText: "SEE REVIEWS"
    
  },
  language: "",
});
const reviewLink = ref("https://cymcorp.us/page/testimonials");
//console.log(",,,,,", translationText.value);
const productSingle = ref({ name: "" });
const decreaseQty = () => {
  if (quantity.value > 1) {
    quantity.value--;
  }
};

const increaseQty = () => {
  quantity.value++;
};

const replaceString = (str) => {
  return str;
  //str.replace("+", "");
};

const rangeCheck = (rangeData, quantity) => {
  let checkFlag = false;
  if (rangeData.length == 2) {
    if (
      quantity >= parseInt(rangeData[0]) &&
      quantity <= parseInt(rangeData[1])
    ) {
      checkFlag = true;
    }

    // for (var i = parseInt(rangeData[0]); i <= parseInt(rangeData[1]); i++) {
    //   if (i == quantity) {
    //     console.log("quantity chec ", i);
    //     checkFlag = true;
    //   }
    // }
  } else if (rangeData.length == 1) {
    if (quantity >= parseInt(rangeData[0])) {
      checkFlag = true;
    }
  } else {
    console.log("jjjjjjjjjjjjjj");
  }

  return checkFlag;
};
const openCartModal = () => {
  let productPrice = 0;

  let range1 = productSingle.value.qty_range_1.replace("+", "").split("-");
  let range2 = productSingle.value.qty_range_2.replace("+", "").split("-");
  let range3 = productSingle.value.qty_range_3.replace("+", "").split("-");

  // Customer Range
  let customerRange1 = productSingle.value.cust_qty_range_1
    .replace("+", "")
    .split("-");
  let customerRange2 = productSingle.value.cust_qty_range_2
    .replace("+", "")
    .split("-");
  let customerRange3 = productSingle.value.cust_qty_range_3
    .replace("+", "")
    .split("-");
  // Customer Range Ends Here

  // console.log("productSingle cart modal qty_range_1 ", range1);

  // console.log("productSingle cart modal qty_range_2 ", range2);

  // console.log("productSingle cart modal qty_range_3 ", range3);

  // console.log("productSingle cart modal range1 length ", range1.length);
  // console.log("productSingle cart modal range2 length ", range2.length);
  // console.log("productSingle cart modal qty_range_3 length ", range3.length);
  // console.log("cartData.getProductQuantity ", cartData.getCartItems);

  let totalQuantity = 0;
  if (cartData.getCartItems.length > 0) {
    cartData.getCartItems.forEach(function (item) {
      if (item.id == productSingle.value.id) {
        totalQuantity = item.quantity;
      }
    });
  }
  totalQuantity += quantity.value;
  // rangeCheck(range1,totalQuantity);
  // rangeCheck(range2,totalQuantity);
  // rangeCheck(range3,totalQuantity);

  console.log("check range1 ", rangeCheck(range1, totalQuantity));
  console.log("check range2 ", rangeCheck(range2, totalQuantity));
  console.log("check range3 ", rangeCheck(range3, totalQuantity));

  let rangeWhich1 = rangeCheck(range1, totalQuantity);
  let rangeWhich2 = rangeCheck(range2, totalQuantity);
  let rangeWhich3 = rangeCheck(range3, totalQuantity);

  let customerRangeWhich1 = rangeCheck(customerRange1, totalQuantity);
  let customerRangeWhich2 = rangeCheck(customerRange2, totalQuantity);
  let customerRangeWhich3 = rangeCheck(customerRange3, totalQuantity);

  if (range1) console.log("totalQuantity ", totalQuantity);

  if (userLogin.checkIsLoggedIn) {
    if (userLogin.getUserUserData.user_type == "ambassador") {
      if (purchaseType.value == 1) {
        productPrice = productSingle.value.amb_sale_price;
      } else if (purchaseType.value == 2) {
        productPrice = productSingle.value.autoship_price_amb;
      } else {
        productPrice = productSingle.value.amb_sale_price;
      }
      if (purchaseType.value != 2 ) {
        if (rangeWhich1) {
          if (productSingle.value.qty_range_1_per > 0) {
            productPrice =
              productPrice -
              (productPrice * productSingle.value.qty_range_1_per) / 100;
          }
        } else if (rangeWhich2) {
          if (productSingle.value.qty_range_2_per > 0) {
            productPrice =
              productPrice -
              (productPrice * productSingle.value.qty_range_2_per) / 100;
          }
        } else if (rangeWhich3) {
          if (productSingle.value.qty_range_3_per > 0) {
            productPrice =
              productPrice -
              (productPrice * productSingle.value.qty_range_3_per) / 100;
          }
        }
      }

    } else if (userLogin.getUserUserData.user_type == "scholar") {
      if (purchaseType.value == 1) {
        productPrice = productSingle.value.sc_sale_price;
      } else if (purchaseType.value == 2) {
        productPrice = productSingle.value.autoship_price_sc;
      } else {
        productPrice = productSingle.value.sc_sale_price;
      }
      if (purchaseType.value != 2 ) {
        if (rangeWhich1) {
          if (productSingle.value.qty_range_1_per > 0) {
            productPrice =
              productPrice -
              (productPrice * productSingle.value.qty_range_1_per) / 100;
          }
        } else if (rangeWhich2) {
          if (productSingle.value.qty_range_2_per > 0) {
            productPrice =
              productPrice -
              (productPrice * productSingle.value.qty_range_2_per) / 100;
          }
        } else if (rangeWhich3) {
          if (productSingle.value.qty_range_3_per > 0) {
            productPrice =
              productPrice -
              (productPrice * productSingle.value.qty_range_3_per) / 100;
          }
        }
      }
    } else {
      if (purchaseType.value == 1) {
        productPrice = productSingle.value.sale_price;
      } else if (purchaseType.value == 2) {
        productPrice = productSingle.value.autoship_price_customer;
      } else {
        productPrice = productSingle.value.sale_price;
      }
      if (purchaseType.value != 2 ) {
        if (customerRangeWhich1) {
          if (productSingle.value.cust_qty_range_1_per > 0) {
            productPrice =
              productPrice -
              (productPrice * productSingle.value.cust_qty_range_1_per) / 100;
          }
        } else if (customerRangeWhich2) {
          if (productSingle.value.cust_qty_range_2_per > 0) {
            productPrice =
              productPrice -
              (productPrice * productSingle.value.cust_qty_range_2_per) / 100;
          }
        } else if (customerRangeWhich3) {
          if (productSingle.value.cust_qty_range_3_per > 0) {
            productPrice =
              productPrice -
              (productPrice * productSingle.value.cust_qty_range_3_per) / 100;
          }
        }
      }

    }

    //let productImage = "";
    // if (productSingle.value.images_url.length > 0) {
    //   productImage = productSingle.value.images_url[0];
    // }
  } else {
     console.log("not logon condition productSingle ",productSingle.value);
    if (purchaseType.value == 1) {
      productPrice = productSingle.value.sale_price;
    } else if (purchaseType.value == 2) {
      productPrice = productSingle.value.autoship_price_customer;
    } else {
      productPrice = productSingle.value.sale_price;
    }
    if (purchaseType.value != 2 ) {
      if (customerRangeWhich1) {
        if (productSingle.value.cust_qty_range_1_per > 0) {
          productPrice =
            productPrice -
            ((productPrice * productSingle.value.cust_qty_range_1_per) / 100).toFixed(2);;
        }
      } else if (customerRangeWhich2) {
        if (productSingle.value.cust_qty_range_2_per > 0) {
          productPrice =
            productPrice -
            ((productPrice * productSingle.value.cust_qty_range_2_per) / 100).toFixed(2);;
        }
      } else if (customerRangeWhich3) {
        if (productSingle.value.cust_qty_range_3_per > 0) {
          productPrice =
            productPrice -
            ((productPrice * productSingle.value.cust_qty_range_3_per) / 100).toFixed(2);
        }
      }
    }
  }

  let productImage = "";
  if (productSingle.value.images_url.length > 0) {
    productImage = productSingle.value.images_url[0];
  }

  console.log("price ", productPrice);
  const cartItem = {
    id: productSingle.value.id,
    name: productSingle.value.name,
    sale_price: productSingle.value.sale_price,
    price: productPrice,
    description: productSingle.value.short_description,
    image: productImage,
    hoverImage: productSingle.value.thumbnail_url,
    quantity: quantity.value,
    purchaseType: purchaseType.value,
  };

  console.log("addto cart cartItem : ",cartItem);
  // console.log("purchaseType : ",purchaseType.value);
  // console.log("quantity : ",quantity.value);
  // console.log("cartItem : ",cartItem);
  cartData.addAllProducts(productSingle.value);
  cartData.addToCart(cartItem);
  $("#cartModal").modal("show");
};

const getProductDetails = () => {
  console.log(props.baseURL);
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const reqUserType = userLogin.checkUserType
    ? "/" + userLogin.checkUserType
    : "/" + "customer";
 // const apiUrl = props.baseURL + "product";
  const apiUrl = props.baseURL + "product";
  

  console.log(
    "product details api",
    apiUrl + "/" + route.params.id + reqUserType + languageUrl
  );
  axios
    .get(apiUrl + "/" + route.params.id + reqUserType + languageUrl)
    .then((response) => {
      //this.products = response.data;
      console.log(response.data.products.length);
      if (response.data.products.length > 0) {
        productSingle.value = response.data.products[0];
        console.log(productSingle);
      }
      //console.log("product data",this.products.products.length);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

const getReview = () => {
  console.log(props.baseURL);
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const reqUserType = userLogin.checkUserType
    ? "/" + userLogin.checkUserType
    : "/" + "customer";
  //const apiUrl = props.baseURL + "product/review/"+route.params.id;
  const apiUrl = props.baseURL + "review/product/"+route.params.id+languageUrl;
  
  
  axios
    .get(apiUrl)
    .then((response) => {
      //this.products = response.data;
      console.log("review ",response.data);
      reviews.value = response.data;
      // if (response.data.products.length > 0) {
      //   productSingle.value = response.data.products[0];
      //   console.log(productSingle);
      // }
      //console.log("product data",this.products.products.length);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};




const translatText = () => { 
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
    console.log("Post translate Text :", translationText.value);
    console.log("Selected Language :", userLanguages.getLanguageCode);
  if(userLanguages.getLanguageCode != 'en'){

    const apiUrl = props.baseURL + "translateany";
  axios
    .post(apiUrl, translationText.value)
    .then((response) => {
      console.log("response translate Text :", response.data);
      translationText.value = response.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });

  }
  else{
     translationText.value = translationTextEn.value;
  }
  
};

onMounted(() => {
  getProductDetails();
  console.log("TranslateText Calling Now");
  translatText();
  getReview();
  console.log("mounted");
});

watch(
  route,
  (to) => {
    getProductDetails();
    getReview();
    console.log("dd");
    translatText(); // Make sure to call this when the route changes
    console.log("Route changed, updating translation...");
  },
  { flush: "pre", immediate: true, deep: true }
);

watch(quantity, (newValue, oldValue) => {
  // For future purpose
  //console.log("quantity :",newValue,productSingle.value.qty_range_1.replace('+','') );
  //const range1 = productSingle.value.qty_range_1.replace('+','').split("-");
  //console.log("range1 array :",range1);
  //const range2 = productSingle.value.qty_range_2.replace('+','').split("-");
  //console.log("range2 array :",range2);
  //const range3 = productSingle.value.qty_range_3.replace('+','').split("-");
  //console.log("range2 array :",range3);
});
watch(purchaseType, (newValue, oldValue) => {
  if (newValue == 1) {
    showSalePrice.value = true;
  } else {
    showSalePrice.value = false;
  }
});
watch(
  () => userLanguages.getLanguageCode,
  (newLang, oldLang) => {
    if (newLang !== oldLang) {
      translatText();
    }
  }
);
onMounted(() => {
  purchaseType.value = "1"; // Ensure the default selection is applied
});
</script>

<style scoped>
.number {
  margin: 100px;
}
.minus,
.plus {
  width: 20px;
  height: 20px;
  background: #f2f2f2;
  border-radius: 4px;
  padding: 8px 5px 8px 5px;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

h4 {
  font-family: "Roboto", sans-serif;
  color: #484848;
  font-weight: 700;
}

#add-product {
  float: right;
  font-weight: 500;
}
.form-radio input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.form-radio .checkmark {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
  margin-right: 8px;
}


.form-radio .checkmark::after {
  content: "";
  position: absolute;
  display: none;
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-radio input[type="radio"]:checked + .checkmark::after {
  display: block;
}
</style>
