import { createRouter, createWebHistory } from "vue-router";
import { useLoginStore } from "../stores/login";
import { useCartStore } from "../stores/cart";
import $ from "jquery";
import Home from "../views/Home.vue";
import User from "../views/User/User.vue";
import Gallery from "../views/User/Gallery.vue";
import AddImage from "../views/User/AddImage.vue";

import PageNotFound from "../views/PageNotFound.vue";

import Product from "../views/Product/Product.vue";
import AddProduct from "../views/Product/AddProduct.vue";
import EditProduct from "../views/Product/EditProduct.vue";
import ShowDetails from "../views/Product/ShowDetails.vue";
import Wishlist from "../views/Product/Wishlist.vue";
import Cart from "../views/Cart/Cart.vue";
import Checkout from "../views/Checkout/Checkout.vue";
import Order from "../views/Orders/Order.vue";

import Category from "../views/Category/Category.vue";
import AddCategory from "../views/Category/AddCategory.vue";
import EditCategory from "../views/Category/EditCategory.vue";
import ListProducts from "../views/Category/ListProducts.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import Scholar from "../views/Scholar.vue";

import Ambassador from "../views/Ambassador.vue";

import Success from "../helper/payment/Success.vue";
import Failed from "../helper/payment/Failed.vue";

import OrderDetails from "../views/Orders/OrderDetails";

// Join Team
import JoinTeam from "../views/JoinTeam/JoinTeam";

// About
import About from "../views/About";
import Faq from "../views/Faq";
import Testimonials from "../views/Testimonials";
import Pressrelease from "../views/Pressrelease";
import PressreleaseDetails from "../views/PressreleaseDetails.vue";
import Blog from "../views/Blog.vue";
import BlogDetails from "../views/BlogDetails.vue";


import ThankYou from "../views/ThankYou";
import ThankYouSignUp from "../views/ThankYouOtherSignUP";
import ReferenceMaterials from "../views/ReferenceMaterials";
import PrivacyPolicy from "../views/PrivacyPolicy";
import PoliciesAndProcedures from "../views/PoliciesAndProcedures";
import SpamPolicy from "../views/SpamPolicy";
import RefundPolicy from "../views/RefundPolicy";
import Terms from "../views/Terms";
// User Starts Here
import Dashboard from "../views/dashboard/Dashboard";
import DownlineGenealogy from "../views/dashboard/DownlineGenealogy";
import OrderListing from "../views/Order/OrderListing";
import UerOrderDetails from "../views/Order/OrderDetails";
import OrderHistory from "../views/Order/OrderHistory";

import CommissionListing from "../views/Commission/CommissionListing";
import TeamOrders from "../views/MyTeam/OrderListing";

import UserCustomers from "../views/UserCustomer/CustomerListing";
import UserCustomerOrderListing from "../views/UserCustomer/OrderListing";
import UserScholar from "../views/UserScholar/CustomerListing";

import UserAmbassadorListing from "../views/UserAmbassador/UserAmbassadorListing";

// Message
import MessageList from "../views/Message/MessageList";
import MessageListDetails from "../views/Message/MessageListDetails";
import MessageCompose from "../views/Message/MessageCompose";
// Profile Starts

import Profile from "../views/Profile/Details";
import ManageAddress from "../views/Profile/ManageAddress";
import ChangePassword from "../views/Profile/ChangePassword";

import ForgetPassword from "../views/ForgetPassword.vue";

// Reorder
import ReOrder from "../views/Order/Reorder";

import Plan from "../views/Plan/Plan";

import VistingCard from "../views/VistingCard";
// Profile Ends Here

// Track Users
import TrackUser from "../views/TrackUser";

// Track Users Ends Here

// User Ends Here

// Ambassador
import AmbassadorTemplate from "../views/User/User.vue";
// Ambassador Ends Here

// Product Thank You Please
import PaymentSuccess from "../views/Checkout/PaymentSuccess.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    alias: "/test/home",
  },
  //User routes
  {
    path: "/forget-password",
    name: "ForgetPassword",
    component: ForgetPassword,
  },
  {
    path: "/user/gallery",
    name: "Gallery",
    component: Gallery,
  },
  {
    path: "/user/gallery/add",
    name: "AddImage",
    component: AddImage,
  },
  //Product routes
  // {
  //   path: "/about",
  //   name: "About",
  //   component: About,
  // },
  {
    path: "/product",
    name: "Product",
    component: Product,
  },
  {
    path: "/user/product",
    name: "UserProduct",
    component: Product,
  },
  {
    path: "/user/product/add",
    name: "AddProduct",
    component: AddProduct,
  },
  {
    path: "/user/product/:id",
    name: "EditProduct",
    component: EditProduct,
  },
  {
    path: "/product/show/:id",
    name: "ShowDetails",
    component: ShowDetails,
  },

  //Category routes
  {
    path: "/category",
    name: "Category",
    component: Category,
  },
  {
    path: "/user/category",
    name: "UserCategory",
    component: Category,
  },
  {
    path: "/user/category/add",
    name: "AddCategory",
    component: AddCategory,
  },
  {
    path: "/user/category/:id",
    name: "EditCategory",
    component: EditCategory,
  },
  {
    path: "/category/show/:id",
    name: "ListProducts",
    component: ListProducts,
  },
  //Page Not found
  // {
  //   path: "/:catchAll(.*)",
  //   name: "PageNotFound",
  //   component: PageNotFound,
  // },
  //Signin and Signup
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/signup-scholar",
    name: "SignupScholar",
    component: Scholar,
  },
  {
    path: "/signup-ambassador",
    name: "SignupAmbassador",
    component: Ambassador,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/join-team",
    name: "JoinTeam",
    component: JoinTeam,
  },

  {
    path: "/wishlist",
    name: "Wishlist",
    component: Wishlist,
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
    alias: "/checkout",
  },
  {
    path: "/order",
    name: "Order",
    component: Order,
  },
  {
    path: "/payment/success",
    name: "PaymentSuccess",
    component: PaymentSuccess,
  },
  {
    path: "/payment/failed",
    name: "FailedPayment",
    component: Failed,
  },
  {
    path: "/order/:id",
    name: "OrderDetails",
    component: OrderDetails,
  },
  {
    path: "/thank-you",
    name: "ThankYou",
    component: ThankYou,
  },
  {
    path: "/signup-success",
    name: "ThankYouSignUp",
    component: ThankYouSignUp,
  },
  // {
  //   path: "/reference-materials",
  //   name: "ReferenceMaterials",
  //   component: ReferenceMaterials,
  // },
  {
    path: "/faq",
    name: "Faq",
    component: Faq,
  },
  {
    path: "/testimonials",
    name: "Testimonials",
    component: Testimonials,
  },
  {
    path: "/press-releases",
    name: "PressRelease",
    component: Pressrelease,
  },
  {
    path: "/press-release-details/:prslug",
    name: "PressReleaseDetails",
    component: PressreleaseDetails,
  },
  {
    path: "/blogs",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/blog-details/:blogSlug",
    name: "BlogDetails",
    component: BlogDetails,
  },
  {
    path: "/visiting-card",
    name: "VistingCard",
    component: VistingCard,
  },
  // {
  //   path: "/testimonials",
  //   name: "Testimonials",
  //   component: Testimonials,
  // },
  // {
  //   path: "/privacy-policy",
  //   name: "PrivacyPolicy",
  //   component: PrivacyPolicy,
  // },

  // {
  //   path: "/policies-and-procedures",
  //   name: "PoliciesAndProcedures",
  //   component: PoliciesAndProcedures,
  // },
  // {
  //   path: "/spam-policy",
  //   name: "SpamPolicy",
  //   component: SpamPolicy,
  // },
  // {
  //   path: "/refund-policy",
  //   name: "RefundPolicy",
  //   component: RefundPolicy,
  // },
  // {
  //   path: "/terms",
  //   name: "Terms",
  //   component: Terms,
  // },

  {
    path: "/:sluguser?",
    name: "TrackUser",
    component: TrackUser,
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
        alias: "/:slug",
      },
      {
        path: "testimonials",
        name: "Testimonials",
        component: Testimonials,
      },
      {
        path: "testimonials",
        name: "TestimonialsUser",
        component: Testimonials,
      },
      {
        path: "press-releases",
        name: "PressRelease",
        component: Pressrelease,
      },
      {
        path: "press-release-details/:prslug",
        name: "PressReleaseDetails",
        component: PressreleaseDetails,
      },
      {
        path: "/blogs",
        name: "Blog",
        component: Blog,
      },
      {
        path: "/blog-details/:blogSlug",
        name: "BlogDetails",
        component: BlogDetails,
      },
      {
        path: "faq",
        name: "Faq",
        component: Faq,
      },

      // Product
      {
        path: "product",
        name: "Product",
        component: Product,
        alias: "/product",
      },
      {
        path: "product",
        name: "ProductUser",
        component: Product,
        //alias: "/product",
      },
      {
        path: "product/show/:id",
        name: "ShowDetailsUser",
        component: ShowDetails,
        alias: ["/:id"],
      },
      // New Routers

      {
        path: "forget-password",
        name: "ForgetPasswordUser",
        component: ForgetPassword,
      },
      {
        path: "signup",
        name: "Signup",
        component: Signup,
      },
      {
        path: "signup-scholar",
        name: "SignupScholar",
        component: Scholar,
      },
      {
        path: "signup-ambassador",
        name: "SignupAmbassador",
        component: Ambassador,
      },
      {
        path: "signin",
        name: "SigninUser",
        component: Signin,
      },
      // {
      //   path: "join-team",
      //   name: "JoinTeam",
      //   component: JoinTeam,
      //   // alias: ["/:sluguser"],
      // },
      {
        path: "checkout",
        name: "CheckoutUser",
        component: Checkout,
      },
      {
        path: "payment/success",
        name: "PaymentSuccess",
        component: PaymentSuccess,
      },
      {
        path: "payment/failed",
        name: "FailedPayment",
        component: Failed,
      },
      {
        path: "order/:id",
        name: "OrderDetails",
        component: OrderDetails,
      },
      {
        path: "thank-you",
        name: "ThankYou",
        component: ThankYou,
      },
      {
        path: "signup-success",
        name: "ThankYouSignUp",
        component: ThankYouSignUp,
      },
      {
        path: ":slug",
        name: "CmsPageUser",
        component: About,
      },
      {
        path: "join-team",
        name: "JoinTeamUser",
        component: JoinTeam,
        // alias: ["/:sluguser"],
      },
      // New ROuters Ends Here

      // User After Login Route

      {
        path: "dashboard",
        name: "DashboardUser",
        component: Dashboard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "downline-genealogy",
        name: "DownlineGenealogyUser",
        component: DownlineGenealogy,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "orders",
        name: "OrdersUser",
        component: OrderListing,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "order/:id",
        name: "OrdersDetailsUser",
        component: UerOrderDetails,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "commission",
        name: "CommissionUser",
        component: CommissionListing,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "order-history",
        name: "OrderHistoryUser",
        component: OrderHistory,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "team-orders",
        name: "TeamOrdersUser",
        component: TeamOrders,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "customers",
        name: "UserCustomersUser",
        component: UserCustomers,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "customer-order-history",
        name: "UserCustomerOrderListingUser",
        component: UserCustomerOrderListing,
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "scholar",
        name: "UserScholarUser",
        component: UserScholar,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "ambassador",
        name: "UserAmbassadorUser",
        component: UserAmbassadorListing,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "message-list",
        name: "MessageListUser",
        component: MessageList,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "message-details/:id",
        name: "MessageListDetailsUser",
        component: MessageListDetails,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "message-compose",
        name: "MessageComposeUser",
        component: MessageCompose,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "profile",
        name: "ProfileUser",
        component: Profile,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "manage-address",
        name: "ManageAddressUser",
        component: ManageAddress,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "change-password",
        name: "ChangePasswordUser",
        component: ChangePassword,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "re-order",
        name: "ReOrderUser",
        component: ReOrder,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "plan",
        name: "PlanUser",
        component: Plan,
        meta: {
          requiresAuth: true,
        },
      },

      // User After Login Route Ends Here
    ],
  },
  {
    path: "/pages/:slug",
    name: "CmsPage",
    component: About,
  },

  {
    path: "/user",
    name: "User",
    component: User,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "downline-genealogy",
        name: "DownlineGenealogy",
        component: DownlineGenealogy,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "orders",
        name: "Orders",
        component: OrderListing,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "order/:id",
        name: "OrdersDetails",
        component: UerOrderDetails,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "commission",
        name: "Commission",
        component: CommissionListing,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "order-history",
        name: "OrderHistory",
        component: OrderHistory,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "team-orders",
        name: "TeamOrders",
        component: TeamOrders,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "customers",
        name: "UserCustomers",
        component: UserCustomers,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "customer-order-history",
        name: "UserCustomerOrderListing",
        component: UserCustomerOrderListing,
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "scholar",
        name: "UserScholar",
        component: UserScholar,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "ambassador",
        name: "UserAmbassador",
        component: UserAmbassadorListing,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "message-list",
        name: "MessageList",
        component: MessageList,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "message-details/:id",
        name: "MessageListDetails",
        component: MessageListDetails,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "message-compose",
        name: "MessageCompose",
        component: MessageCompose,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "profile",
        name: "Profile",
        component: Profile,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "manage-address",
        name: "ManageAddress",
        component: ManageAddress,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "change-password",
        name: "ChangePassword",
        component: ChangePassword,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "re-order",
        name: "ReOrder",
        component: ReOrder,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "plan",
        name: "Plan",
        component: Plan,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/ambassador",
    name: "Ambassador",
    component: AmbassadorTemplate,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "dashboard",
        name: "AmbassadorDashboard",
        component: Dashboard,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//scroll to top after every route change
router.beforeEach((to, from, next) => {
  console.log("slug router", to.params);
  const storeLogin = useLoginStore();
  const cartStore = useCartStore();
  console.log("store login country", storeLogin.getUserCountry);
    $(document).ready(function () {
    window.$("#slidemenu").removeClass("menu-open");
    window.$(".slidemenu-overlay").removeClass("open");
    });
  if (storeLogin.getUserCountry == null) {
    $(document).ready(function () {
      window.$("#exampleModal").modal("show");
    });
  }

  window.scrollTo(0, 0);
  // Optionally, perform async actions such as checking prices
  
  console.log(`Name Signing ${from.path}`);
  console.log(`isloggedin ${storeLogin.isLoggedIn}`);
  console.log(`cart items ${cartStore.countCartItems}`);
  if (cartStore.countCartItems > 0 && from.path === '/signin' && storeLogin.isLoggedIn) {
    // Clear the cart
    cartStore.resetAllData();

    // Optionally notify the user
    alert("Your cart has been cleared due to a change in pricing. Please re-add your items.");
  }

  if (storeLogin.userUrlName != null) {
    console.log("storeLogin.getReferalId router all data", to);

    if (to.name == "ShowDetails") {
      next({
        name: "ShowDetailsUser",
        params: { sluguser: storeLogin.getUserUrlName, id: to.params.id },
      });
    } else if (to.name == "Product") {
      next({
        name: "ProductUser",
        params: { sluguser: storeLogin.getUserUrlName },
      });
    } else if (to.name == "Testimonials") {
      next({
        name: "TestimonialsUser",
        params: { sluguser: storeLogin.getUserUrlName },
      });
    } else if (to.name == "Dashboard") {
      next({
        name: "DashboardUser",
        params: { sluguser: storeLogin.getUserUrlName },
      });
    } else if (to.path == "/about-us") {
      next({
        name: "CmsPageUser",
        params: { sluguser: storeLogin.getUserUrlName, slug: to.params.slug },
      });
    } else if (to.path == "/checkout") {
      next({
        name: "CheckoutUser",
        params: { sluguser: storeLogin.getUserUrlName, slug: to.params.slug },
      });
    } else if (to.path == "/join-team") {
      next({
        name: "JoinTeamUser",
        params: { sluguser: storeLogin.getUserUrlName, slug: to.params.slug },
      });
    } else {
      console.log("storeLogin.getReferalId else next");
      next();
    }
  } else {
    console.log("else part not userinfo ", to);
    next();
  }

});

router.afterEach((to) => {
   // Add Checkout Page Tracking
  if (to.path === '/payment/success') {
    // Google Ads Conversion Tracking
    gtag('event', 'conversion', {
      'send_to': 'AW-870466601/bNbzCP3S2b0ZEKmIiZ8D',
      'value': 1.0,
      'currency': 'CAD'
    });

    // Facebook Pixel Purchase Tracking
    fbq('track', 'Purchase', {
      value: 23.96,
      currency: 'USD',
      contents: [
          {
              quantity: 1
          }
      ],
    });
  } 
  if (to.path === '/checkout') {
    // Google Ads Conversion Tracking
    gtag('event', 'conversion', {
      'send_to': 'AW-16857523017/Oa1dCJ6qqpoaEMnGpeY-',
      'value': 1.0,
      'currency': 'USD',
      'transaction_id': ''
    });

    // Facebook Pixel Purchase Tracking
    fbq('track', 'AddToCart', {
      value: 23.96,
      currency: 'USD',
      contents: [
          {
              quantity: 1
          }
      ],
    });

  }

});

export default router;
